<template>
    <el-dialog title="拉黑设置" width="50%" :close-on-click-modal="false" :visible.sync="visible">
        <el-form class="p_l2" size="small" :model="inputForm" ref="inputForm" label-width="120px">
            <div class="p_b1 fontSize18">设置触发条件</div>
            <el-form-item label="触发方式：" prop="blackRule"
                          :rules="[{ required: true, message: '请选择触发方式', trigger: 'blur' }]">
                <el-select v-model="inputForm.blackRule" placeholder="请选择" class="m_r1">
                    <el-option
                            v-for="item in blackRuleOpt"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="触发次数：" prop="blackNums"
                          :rules="[{ required: true, message: '请选择触发次数', trigger: 'blur' }]">
                <el-select v-model="inputForm.blackNums" placeholder="请选择" class="m_r1" @change="changeBlackNums">
                    <el-option
                            v-for="item in blackNumsOpt"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                <el-input v-if="inputForm.blackNums == 0" size="small" maxlength="3" v-model="customBlackNums" oninput="if(value<1)value=1" onkeyup="value=value.replace(/[^\d]/g,'')" @blur="customBlackNumsChange" placeholder="请输入触发次数" style="width: 30%"></el-input>
            </el-form-item>
            <div class="p_t2 p_b1 fontSize18">设置拉黑规则</div>
            <el-form-item label="禁止天数：" prop="blackDay"
                          :rules="[{ required: true, message: '请选择禁止天数', trigger: 'blur' }]">
                <el-select v-model="inputForm.blackDay" placeholder="选择天数" class="m_r1" @change="changeBlackDay">
                    <el-option
                            v-for="item in blackDayOpt"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                <el-input v-if="inputForm.blackDay == 0" size="small" maxlength="3" v-model="customBlackDay" oninput="if(value<1)value=1" onkeyup="value=value.replace(/[^\d]/g,'')" @blur="customBlackDayChange" placeholder="请输入禁止天数" style="width: 30%"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button size="small" type="primary" @click="submit()" v-noMoreClick>提交</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                inputForm: {
                    blackDay: null,
                    blackNums: null,
                    blackRule: null,
                },
                customBlackNums: null,
                customBlackDay: null,
                blackRuleOpt: [
                    {
                        label: '累计',
                        value: 0
                    },
                    {
                        label: '近3月',
                        value: 90
                    },
                    {
                        label: '近半年',
                        value: 180
                    },
                    {
                        label: '近1年',
                        value: 365
                    }
                ],
                blackNumsOpt: [
                    {
                        label: '1次',
                        value: 1
                    },
                    {
                        label: '2次',
                        value: 2
                    },
                    {
                        label: '3次',
                        value: 3
                    },
                    {
                        label: '自定义',
                        value: 0
                    }
                ],
                blackDayOpt:[
                    {
                        label: '3天',
                        value: 3
                    },
                    {
                        label: '7天',
                        value: 7
                    },
                    {
                        label: '自定义',
                        value: 0
                    }
                ],
            }
        },
        methods: {
            init() {
                this.visible = true
                this.$nextTick(() => {
                    console.log(this.inputForm);
                    this.$refs.inputForm.resetFields();
                    this.getData()
                })
            },
            // 获取配置
            getData() {
                this.$axios(this.api.order.getBaseMakeBlack).then((res) => {
                    if (res.status) {
                        this.recover(this.inputForm, res.data);
                        let {blackNums, blackDay} = this.inputForm
                        if(parseInt(blackNums) > 3) {
                            this.customBlackNums = blackNums
                            this.$set(this.inputForm, 'blackNums', 0)
                        }
                        if(blackDay != 3 && blackDay != 7) {
                            this.customBlackDay = blackDay
                            this.$set(this.inputForm, 'blackDay', 0)
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
            // 提交
            submit() {
                let {blackNums, blackDay} = this.inputForm
                if(parseInt(blackNums) == 0) {
                    if(this.customBlackNums == null || this.customBlackNums == '') {
                        this.$message('请输入触发次数');
                        return false;
                    }
                }
                if(blackDay == 0 && this.customBlackDay == null || this.customBlackDay == '') {
                    this.$message('请输入禁止天数');
                    return false;
                }
                let param = JSON.parse(JSON.stringify(this.inputForm))
                if(blackNums == 0 && this.customBlackNums) {
                    param.blackNums = parseInt(this.customBlackNums)
                }
                if(blackDay == 0 && this.customBlackDay) {
                    param.blackDay = parseInt(this.customBlackDay)
                }
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.$axios(this.api.order.basemakeblackSave, param, 'post').then((res) => {
                            if (res.status) {
                                this.$message.success('设置成功');
                                this.visible = false
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }
                })
            },
            // 触发次数改变
            changeBlackNums() {
                this.customBlackNums = null
            },
            // 禁止天数改变
            changeBlackDay() {
                this.customBlackDay = null
            },
            // 自定义触发次数改变
            customBlackNumsChange(e) {
                this.customBlackNums = e.target.value
            },
            // 自定义禁止天数改变
            customBlackDayChange(e) {
                this.customBlackDay = e.target.value
            },
        }
    }
</script>

<style scoped>

</style>
